import React from "react";
import { createTheme, responsiveFontSizes, alpha as fade, Theme, ThemeOptions } from "@material-ui/core/styles";
import { CreateCSSProperties, CSSProperties } from "@material-ui/core/styles/withStyles";

interface IFontTypes {
    book: CSSProperties;
    regular: CSSProperties;
    mediumItalic: CSSProperties;
    bold: CSSProperties;
}

export interface IStyles {
    [key: string]: React.CSSProperties | {};
}

// Extend theme properties with module augmentation
declare module "@material-ui/core" {
    interface ITheme extends Theme {
        stats: {
            purple: string;
            purple_light: string;
            blue: string;
            mint: string;
            orange: string;
            red: string;
            green: string;
            aqua: string;
            pink: string;
        };
        variants: {
            offWhite: string;
            lightWhite: string;
            lightGrey: string;
            tableHeaderColour: string;
            purple: string;
            visualiserBackground: string;
            green: string;
            grey: string;
            transparentColour: string;
            EAOrange: string;
            EALightGray: string;
            EACharGray: string;
            EALightBlue: string;
            EADisabled: string;
            EALightPurple: string;
            EALilac: string;
            accessCodeBox: string;
            twitterBlue: string;
            facebookBlue: string;
            whatsappGreen: string;
            discordBlue: string;
            yellow: string;
            errorRed: string;
        };
        sidebar: {
            backgroundColor: string;
            color: string;
            logoColor: string;
            hoverState: string;
            navSelected: {
                background: string;
                backgroundSize: string;
                boxShadow: string;
            };
        };
        cookieBanner: {
            backgroundColor: string;
            border: string;
        };
        mobileHeader: {
            backgroundColor: string;
            selectedTabBackground: string;
            selectedTabColor: string;
            tabColor: string;
        };
        styles: {
            defaultHoverOpacity: number;
            hoverEaseInOut: string;
            defaultBoxShadow: string;
            roundedBodyBoxShadow: string;
            borderRadiusXxs: number;
            borderRadiusXs: number;
            borderRadiusSm: number;
            borderRadiusMd: number;
            borderRadiusLg: number;
            skeleton: {
                color: (theme: Theme) => string;
                animation: any;
            };
            headerOverlay: {
                backgroundImage: string;
                opacity: number;
            };
            dropdownBorder: string;
            borderBottom: string;
            leaderboardBorderBottom: string;
            borderBottomColor: string;
            boxShadowColour: string;
            cssImportant: string;
            transparentBg: string;
            transparentButton: string;
            popoverBackground: string;
        };
        layout: {
            sidebarWidth: number;
            defaultPageSectionMaxWidth: number;
            scrollbarWidth: number;
            toolBarHeight: number;
        };
    }
}

export const fonts = {
    primary: "ElectronicArtsDisplay, Helvetica, sans-serif",
    secondary: "ElectronicArtsText, Helvetica, sans-serif",
};

export const fontTypes: IFontTypes = {
    bold: {
        fontFamily: fonts.primary,
        fontWeight: 700,
    },
    book: {
        fontFamily: fonts.secondary,
        fontWeight: 300,
    },
    regular: {
        fontFamily: fonts.secondary,
        fontWeight: 400,
    },
    mediumItalic: {
        fontFamily: fonts.secondary,
        fontWeight: 500,
        fontStyle: "italic",
    },
};

export const layout = {
    sidebarWidth: 80,
    defaultPageSectionMaxWidth: 1440,
    hubV3MaxWidth: 1693,
    scrollbarWidth: 7,
    toolBarHeight: 60,
    mobileTopInset: 70,
};

export const getDesktopToolbarSpacing = (theme: Theme) => {
    return {
        horizontal: theme.spacing(2),
        vertical: theme.spacing(2),
        height: 48,
    };
};

export const paCar1Colour = "#19caf8";
export const paCar2Colour = "#f3d03d";
export const paScrubberDefaultColour = "#F2F2F2";
export const paScrubberDefaultColourHover = "#F2F2F2";

export const f1ConstructorColors = {
    redBull: "#001A30",
    haas: "#000",
    ferrari: "#E1251B",
    mcLaren: "#231F20",
    mercedes: "#000",
    astonMartin: "#005850",
    alpine: "#0C1D2C",
    sauber: "#000",
    alphaTauri: "#1D43D8",
    williams: "#041E42",
};

// Keep for Canvas.tsx, Track.tsx.
export const stats_orange = "#e39b26";
export const lightWhite = "#FFFFFF";
export const lightGrey = "#e6e6e6";

const baseTheme: Theme = responsiveFontSizes(
    createTheme({
        palette: {
            common: {
                white: "#F2F2F2",
                black: "#000",
            },
        },
        stats: {
            purple_light: "#964490",
            mint: "#72efdd",
            red: "#F7004a",
            aqua: "#4EC2CB",
            pink: "#c52a96",
        },
        variants: {
            offWhite: "#F2F2F2",
            lightWhite: "#FFFFFF",
            lightGrey: "#e6e6e6",
            tableHeaderColour: "#F8F8F8",
            purple: "#221421",
            visualiserBackground: "#2f222b",
            green: "#42ba2e",
            grey: "#685767",
            transparentColour: "transparent",
            EAOrange: "#FF4747",
            EALightGray: "#EFEFEF",
            EACharGray: "#63666A",
            EADisabled: "#908E97",
            EALightPurple: "#AFB4F2",
            EALilac: "#B0B5F3",
            EABlue: "#0D1042",
            EALightBlue: "#272C7D",
            accessCodeBox: "#2B2D40",
            twitterBlue: "#1D9BF0",
            facebookBlue: "#1877F2",
            whatsappGreen: "#25D366",
            discordBlue: "#5865F2",
            yellow: "#D0DA46",
            errorRed: "#CB1F1F",
        },
        sidebar: {
            backgroundColor: "#040C1F",
            color: "#F2F2F2 !important",
            logoColor: "#FF4747",
            hoverState: "#161A59",
            navSelected: {
                //background: "linear-gradient(72deg, #091329, #0a1a3e)",
                background: "#B0B6F4",
                color: "#1B2064 !important",
                backgroundSize: "100%",
                /*boxShadow: `
            inset 0px 11px 11px -10px #040c1e,
            inset 0px -11px 11px -10px #040c1e; 
            `,*/
            },
        },
        cookieBanner: {
            backgroundColor: "#1B2064",
            border: "#B0B6F4",
        },
        mobileHeader: {
            backgroundColor: "#1B2064",
            selectedTabBackground: "#B0B6F4",
            selectedTabColor: "#131850",
            tabColor: "#B0B6F4",
        },
        styles: {
            defaultHoverOpacity: 0.8,
            hoverEaseInOut: "all .2s ease-in-out",
            defaultBoxShadow: `-2px 2px 4px 0px ${fade("#221421", 0.4)}`,
            roundedBodyBoxShadow: `-4px 0px 4px 0px ${fade("#221421", 0.3)}`,
            borderRadiusXxs: 2,
            borderRadiusXs: 4,
            borderRadiusSm: 8,
            borderRadiusMd: 16,
            borderRadiusLg: 32,
            skeleton: {
                color: (theme: Theme) => theme.palette.grey[200],
                animation: "wave" as "wave",
            },
            headerOverlay: {
                opacity: 0.5,
                backgroundImage: "linear-gradient(to right, #0D1043 , #1B2064)",
            },
            borderBottomColor: "#e0e0e0",
            boxShadowColour: "#221421",
            cssImportant: " !important",
        },
        layout: {
            sidebarWidth: 80,
            defaultPageSectionMaxWidth: 1440,
            scrollbarWidth: 7,
            toolBarHeight: 56,
        },
        typography: {
            // Base font size
            // fontSize: 16,
            h1: {
                ...fontTypes.bold,
            },
            h2: {
                ...fontTypes.bold,
            },
            h3: {
                ...fontTypes.bold,
            },
            h4: {
                ...fontTypes.bold,
            },
            h5: {
                ...fontTypes.bold,
            },
            h6: {
                ...fontTypes.bold,
            },
            subtitle1: {
                ...fontTypes.bold,
            },
            subtitle2: {
                ...fontTypes.bold,
            },
            body1: {
                ...fontTypes.regular,
            },
            body2: {
                ...fontTypes.regular,
            },
            p: {
                ...fontTypes.regular,
            },
            button: { ...fontTypes.bold, textTransform: "none" },
            caption: {
                ...fontTypes.regular,
            },
            overline: {
                ...fontTypes.regular,
            },
        },
    } as ThemeOptions)
);

// Extended darkTheme
const darkTheme: Theme = createTheme(baseTheme, {
    palette: {
        primary: {
            main: "#131850",
            dark: "#040C1F",
            light: "#161A59",
            contrastText: "#F2F2F2",
        },
        secondary: {
            main: "#131850",
            dark: "#131850",
            light: "#B0B6F4",
            contrastText: "#F2F2F2",
        },
        info: {
            main: "#FF4747",
            light: "#B0B6F4",
            dark: "#161A59",
        },
        // success: {},
        error: {
            main: "#CB1F1F",
        },
        // warning: {},
        background: {
            default: "#0D1043",
            paper: "#1B2064",
        },
        text: {
            primary: "#FF4747",
            secondary: "#F2F2F2",
            disabled: "#B0B6F4",
        },
    },
    styles: {
        dropdownBorder: "#B0B6F4",
        borderBottom: "1px solid #B0B6F4",
        leaderboardBorderBottom: `0.5px solid #131850`,
        transparentBg: "#fff",
        transparentButton: "#0D1043",
        popoverBackground: "rgba(21, 22, 31, 0.6)",
    },
    stats: {
        purple: "#872ae6",
        blue: "#19caf8",
        green: "#75d054",
        orange: "#ff7800",
    },
    overrides: {
        MuiListItemIcon: {
            root: {
                color: "#F2F2F2",
            },
        },
        MuiToolbar: {
            root: {
                height: layout.toolBarHeight,
                minHeight: layout.toolBarHeight + "!important",
            },
        },
    },
});

// Extended lightTheme
const lightTheme: Theme = createTheme(baseTheme, {
    palette: {
        primary: {
            main: "#131850",
            dark: "#040C1F",
            light: "#E6E6E6", // grey
            contrastText: "#F2F2F2",
        },
        secondary: {
            main: "#E6E6E6", // grey
            // main: "#685767",
            light: "#EEEEEE",
            dark: "#040C1F",
            contrastText: "#685767",
        },
        info: {
            main: "#FF4747",
            light: "#685767",
            dark: "#161A59",
        },
        // success: {},
        // error: {},
        // warning: {},
        background: {
            default: "#0D1043",
            paper: "#F2F2F2",
        },
        text: {
            primary: "#040C1F",
            // secondary: "#131850",
            secondary: "#040C1F",
            disabled: "#685767",
        },
    },
    styles: {
        dropdownBorder: "#040C1F",
        borderBottom: "1px solid #E0E0E0",
        leaderboardBorderBottom: `0.5px solid #E0E0E0`,
        transparentBg: "#0D1043",
        transparentButton: "#fff",
    },
    stats: {
        purple: "#7400b8",
        blue: "#4ea8de",
        orange: "#e39b26",
        green: "#75D054",
    },
    overrides: {
        MuiListItemIcon: {
            root: {
                color: "F2F2F2",
            },
        },
        MuiToolbar: {
            root: {
                height: layout.toolBarHeight,
                minHeight: layout.toolBarHeight + "!important",
            },
        },
    },
});

export { lightTheme, darkTheme };

export type StyleGuideVariant = "v1" | "v2";

const grey = "#1D2033";
const richGrey = "#272C40";
const richBlack = "#141724";
const white = "#FFFFFF";
const whiteHover = "#EAEAEA";
const offWhite = "#CCCCCC";
const black = "#000";
const periwinkle = "#C0C1DB";
const coral = "#FF4747";
const coralHover = "#DC2929";
const blue = "#3471E9";
const blueHover = "#1A53C4";
const blueSelected = "#2A62CE";
const inputGrey = "#797A9E";
const inputBlack = "#111427";
const textDark = "#141724";
const darkerBlack = "#0B0E15";
const transparent = "transparent";
const disabledBg = "#636270";
const disabledText = "#29292E";
const gold = "#DCBE55";
const silver = "#8A97A4";
const bronze = "#8C5D35";
const red = "#CB1F1F";
const green = "#5EC61B";
const lightGreen = "#75d054";
const yellow = "##FCEE21";

const EADisplayFont = "ElectronicArtsDisplay, Helvetica, sans-serif";
export const EATextFont = "ElectronicArtsText, Helvetica, sans-serif";

const buttonStyles: IStyles = {
    fontFamily: EADisplayFont,
    fontWeight: 700,
    fontSize: 13,
    letterSpacing: 1.3,
    textTransform: "uppercase",
    margin: 0,
    cursor: "pointer",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    lineHeight: 1,
};

//New style guide theme
export const styleGuide = {
    color: {
        grey: grey,
        richBlack: richBlack,
        richGrey: richGrey,
        white: white,
        whiteHover: whiteHover,
        black: black,
        periwinkle: periwinkle,
        coral: coral,
        coralHover: coralHover,
        blue: blue,
        blueHover: blueHover,
        blueSelected: blueSelected,
        inputGrey: inputGrey,
        textDark: textDark,
        inputBlack: inputBlack,
        darkerBlack: darkerBlack,
        transparent: transparent,
        disabledBg: disabledBg,
        disabledText: disabledText,
        paCar1Colour: paCar1Colour,
        paCar2Colour: paCar2Colour,
        gold: gold,
        silver: silver,
        bronze: bronze,
        red: red,
        green: green,
        lightGreen: lightGreen,
        offWhite: offWhite,
        yellow: yellow,
    },
    variant: {
        EADisabled: "#908E97",
        greenBg: "#42ba2e",
        yellowBg: "#D0DA46",
    },
    typography: {
        titleXL: {
            fontFamily: EADisplayFont,
            fontWeight: 700,
            fontSize: 56,
            color: white,
            lineHeight: 1.4,
            margin: 0,
        },
        title: {
            fontFamily: EADisplayFont,
            fontWeight: 700,
            fontSize: 40,
            color: white,
            lineHeight: 1.4,
            margin: 0,
        },
        heading: {
            fontFamily: EADisplayFont,
            fontWeight: 700,
            fontSize: 24,
            color: white,
            lineHeight: 1.1,
            margin: 0,
        },
        subHeading: {
            fontFamily: EADisplayFont,
            fontWeight: 700,
            fontSize: 20,
            color: white,
            lineHeight: 1.1,
        },
        heading3: {
            fontFamily: EATextFont,
            fontWeight: 400,
            fontSize: 20,
            color: white,
            lineHeight: 1.1,
        },
        copy: {
            fontFamily: EATextFont,
            fontWeight: 400,
            fontSize: 16,
            color: white,
            lineHeight: 1.1,
        },
        copyText: {
            fontFamily: EATextFont,
            fontWeight: 400,
            fontSize: 16,
            color: white,
            lineHeight: 1.2,
        },
        input: {
            fontFamily: EATextFont,
            fontWeight: 400,
            fontSize: 16,
            color: white,
            lineHeight: 1.1,
        },
        caption: {
            fontFamily: EATextFont,
            fontWeight: 400,
            fontSize: 12,
            color: white,
            lineHeight: 1.1,
        },
        button: {
            ...buttonStyles,
        },
    },
    breakpoints: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
    fontTypes: {
        bold: {
            fontWeight: 700,
        },
        regular: {
            fontWeight: 400,
        },
    },
    button: {
        regular: {
            default: {
                ...buttonStyles,
                backgroundColor: coral,
                color: white,
                height: 33,
                border: `2px solid ${coral}`,
                borderRadius: 8,
                minWidth: 140,
                padding: "0 24px",
            },
            jumbo: {
                ...buttonStyles,
                backgroundColor: coral,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 18,
                fontWeight: 700,
                height: 49,
                border: `2px solid ${coral}`,
                borderRadius: 8,
                minWidth: 220,
                padding: "0 32px",
                letterSpacing: 1,
            },
            mobile: {
                ...buttonStyles,
                backgroundColor: coral,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 16,
                fontWeight: 700,
                height: 50,
                border: `2px solid ${coral}`,
                borderRadius: 8,
                width: "100%",
                padding: "0 12px",
                letterSpacing: 0.8,
            },
            hover: {
                backgroundColor: coralHover,
                color: "#EAEAEA",
                border: `2px solid ${coralHover}`,
            },
            inactive: {
                backgroundColor: "#636270",
                color: "#29292E",
                border: `2px solid #636270`,
                cursor: "default",
            },
        },
        outlinedPrimary: {
            default: {
                ...buttonStyles,
                backgroundColor: "transparent",
                border: `2px solid ${coral}`,
                color: white,
                height: 33,
                borderRadius: 8,
                minWidth: 140,
                padding: "0 24px",
            },
            jumbo: {
                ...buttonStyles,
                backgroundColor: "transparent",
                border: `2px solid ${coral}`,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 18,
                fontWeight: 700,
                textTransform: "uppercase",
                height: 49,
                borderRadius: 8,
                minWidth: 220,
                padding: "0 32px",
                letterSpacing: 1,
            },
            mobile: {
                ...buttonStyles,
                backgroundColor: "transparent",
                border: `2px solid ${coral}`,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 16,
                fontWeight: 700,
                textTransform: "uppercase",
                height: 50,
                borderRadius: 8,
                width: "100%",
                padding: "0 12px",
                letterSpacing: 0.8,
            },
            hover: {
                backgroundColor: coralHover,
                border: `2px solid ${coralHover}`,
                color: "#EAEAEA",
            },
            inactive: {
                backgroundColor: "transparent",
                border: `2px solid #636270`,
                color: "#636270",
                cursor: "default",
            },
        },
        outlinedSecondary: {
            default: {
                ...buttonStyles,
                backgroundColor: "transparent",
                border: `2px solid ${white}`,
                color: white,
                height: 33,
                borderRadius: 8,
                minWidth: 140,
                padding: "0 24px",
            },
            jumbo: {
                ...buttonStyles,
                backgroundColor: "transparent",
                border: `2px solid ${white}`,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 18,
                fontWeight: 700,
                textTransform: "uppercase",
                height: 49,
                borderRadius: 8,
                minWidth: 220,
                padding: "0 32px",
                letterSpacing: 1,
            },
            mobile: {
                ...buttonStyles,
                backgroundColor: "transparent",
                border: `2px solid ${white}`,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 16,
                fontWeight: 700,
                textTransform: "uppercase",
                height: 50,
                borderRadius: 8,
                width: "100%",
                padding: "0 12px",
                letterSpacing: 0.8,
            },
            hover: {
                backgroundColor: white,
                border: `2px solid ${white}`,
                color: "#14152E",
            },
            inactive: {
                backgroundColor: "transparent",
                border: `2px solid #636270`,
                color: "#636270",
                cursor: "default",
            },
        },
        outlinedBlue: {
            default: {
                ...buttonStyles,
                backgroundColor: "transparent",
                border: `2px solid ${blue}`,
                color: white,
                height: 33,
                borderRadius: 8,
                minWidth: 140,
                padding: "0 24px",
            },
            jumbo: {
                ...buttonStyles,
                backgroundColor: "transparent",
                border: `2px solid ${blue}`,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 18,
                fontWeight: 700,
                textTransform: "uppercase",
                height: 49,
                borderRadius: 8,
                minWidth: 220,
                padding: "0 32px",
                letterSpacing: 1,
            },
            mobile: {
                ...buttonStyles,
                backgroundColor: "transparent",
                border: `2px solid ${blue}`,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 16,
                fontWeight: 700,
                textTransform: "uppercase",
                height: 50,
                borderRadius: 8,
                width: "100%",
                padding: "0 12px",
                letterSpacing: 0.8,
            },
            hover: {
                backgroundColor: blue,
                border: `2px solid ${blue}`,
                color: white,
            },
            inactive: {
                backgroundColor: "transparent",
                border: `2px solid #636270`,
                color: "#636270",
                cursor: "default",
            },
        },
        segmented: {
            default: {
                ...buttonStyles,
                backgroundColor: blue,
                border: `2px solid ${blue}`,
                color: white,
                height: 33,
                borderRadius: 8,
                minWidth: 140,
                padding: "0 24px",
            },
            jumbo: {
                ...buttonStyles,
                backgroundColor: blue,
                border: `2px solid ${blue}`,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 18,
                fontWeight: 700,
                textTransform: "uppercase",
                height: 49,
                borderRadius: 8,
                minWidth: 220,
                padding: "0 32px",
                letterSpacing: 1,
            },
            mobile: {
                ...buttonStyles,
                backgroundColor: blue,
                border: `2px solid ${blue}`,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 16,
                fontWeight: 700,
                textTransform: "uppercase",
                height: 50,
                borderRadius: 8,
                width: "100%",
                padding: "0 12px",
                letterSpacing: 0.8,
            },
            hover: {
                backgroundColor: blueHover,
                border: `2px solid ${blueHover}`,
                color: "#EAEAEA",
            },
            inactive: {
                backgroundColor: "transparent",
                border: `2px solid #636270`,
                color: "#636270",
                cursor: "default",
            },
        },
        pill: {
            default: {
                ...buttonStyles,
                backgroundColor: blue,
                border: `2px solid ${blue}`,
                color: white,
                height: 33,
                borderRadius: 18,
                minWidth: 140,
                padding: "0 24px",
            },
            jumbo: {
                ...buttonStyles,
                backgroundColor: blue,
                border: `2px solid ${blue}`,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 18,
                fontWeight: 700,
                textTransform: "uppercase",
                height: 44,
                borderRadius: 25,
                minWidth: 220,
                padding: "0 32px",
                letterSpacing: 1,
            },
            mobile: {
                ...buttonStyles,
                backgroundColor: blue,
                border: `2px solid ${blue}`,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 16,
                fontWeight: 700,
                textTransform: "uppercase",
                height: 44,
                borderRadius: 25,
                width: "100%",
                padding: "0 12px",
                letterSpacing: 0.8,
            },
            hover: {
                backgroundColor: blueHover,
                border: `2px solid ${blueHover}`,
                color: white,
            },
            inactive: {
                backgroundColor: blue,
                border: `2px solid #636270`,
                color: "#636270",
                cursor: "default",
            },
        },
        dialogSelector: {
            default: {
                ...buttonStyles,
                backgroundColor: richBlack,
                border: `2px solid ${richBlack}`,
                color: white,
                height: 33,
                borderRadius: 8,
                minWidth: 140,
                padding: "0 24px",
            },
            jumbo: {
                ...buttonStyles,
                backgroundColor: richBlack,
                border: `2px solid ${richBlack}`,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 18,
                fontWeight: 700,
                textTransform: "uppercase",
                height: 44,
                borderRadius: 8,
                minWidth: 220,
                padding: "0 32px",
                letterSpacing: 1,
            },
            mobile: {
                ...buttonStyles,
                backgroundColor: richBlack,
                border: `2px solid ${richBlack}`,
                color: white,
                fontFamily: EADisplayFont,
                fontSize: 16,
                fontWeight: 700,
                textTransform: "uppercase",
                height: 44,
                borderRadius: 8,
                width: "100%",
                padding: "0 12px",
                letterSpacing: 0.8,
            },
            hover: {
                backgroundColor: white,
                border: `2px solid ${white}`,
                color: richBlack,
                boxShadow: `0 0 8px ${periwinkle}BF`,
            },
            inactive: {
                backgroundColor: richBlack,
                border: `2px solid #636270`,
                color: "#636270",
                cursor: "default",
            },
        },
        link: {
            default: {
                ...buttonStyles,
                backgroundColor: "transparent",
                color: coral,
                height: 33,
                border: "none",
                borderRadius: 8,
                minWidth: 140,
                padding: "0 24px",
            },
            jumbo: {
                ...buttonStyles,
                backgroundColor: "transparent",
                color: coral,
                fontFamily: EADisplayFont,
                fontSize: 18,
                fontWeight: 700,
                height: 49,
                border: "none",
                borderRadius: 8,
                minWidth: 220,
                padding: "0 32px",
                letterSpacing: 1,
            },
            mobile: {
                ...buttonStyles,
                backgroundColor: "transparent",
                color: coral,
                fontFamily: EADisplayFont,
                fontSize: 16,
                fontWeight: 700,
                height: 50,
                border: "none",
                borderRadius: 8,
                width: "100%",
                padding: "0 12px",
                letterSpacing: 0.8,
            },
            hover: {
                backgroundColor: "none",
                color: coralHover,
                border: "none",
            },
            inactive: {
                backgroundColor: "none",
                color: "#29292E",
                border: "none",
                cursor: "default",
            },
        },
    },
    popoverBackground: {
        backgroundColor: `${richBlack}80`, //50% opacity
    },
    borderRadius: 8,
    boxShadow: {
        white: `0 0 8px ${periwinkle}BF`,
    },
    transitions: {
        hoverEaseInOut: "all .2s ease-in-out",
    },
    spacing: (value: number = 1) => value * 8,
};
