import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { analyticsIds, routingUrls, TabTitle } from "settings";
import ScrollToTop from "./ScrollToTop";
import { useIdentityContext } from "contexts/identity/IdentityContext";
import { NotificationContextProvider } from "contexts/notification/NotificationContext";
import AuthorisedNavigationBar from "containers/navigation/authorised/AuthorisedNavigationBar";
import PageCircularProgress from "common/components/loaders/PageCircularProgress";
import { useApplicationContext } from "contexts/application/ApplicationContext";
import GamesEnum from "common/enums/GamesEnum";
import { Helmet } from "react-helmet";
import OnboardingDialog from "common/components/OnboardingDialog/OnboardingDialog";
import { isRaceNetMobileApp, isRaceNetTabletApp } from "utils/webViewTools";
import { useConfig } from "../contexts/configuration/ConfigurationContext";
import LaunchDialogsController from "common/components/launchDialogs/LaunchDialogsController";
import useIsMobile from "hooks/useIsMobile";

const Games = lazy(() => import("../pages/games/GamesPage"));
const LoggedInNotFoundPage = lazy(() => import("../pages/errorPages/LoggedInNotFoundPage"));
const ProfileRoutes = lazy(() => import("../pages/profile/routes"));
const Friends = lazy(() => import("../pages/friends"));
const Preferences = lazy(() => import("../pages/preferences"));
const News = lazy(() => import("../pages/newsFeed/NewsRoutes"));
const ReleaseNotes = lazy(() => import("../pages/releaseNotes/ReleaseNotes"));
const Faq = lazy(() => import("../pages/faq/FaqPage"));

const DRRoutes = lazy(() => import("../pages/gamesStats/dirtRally/DRRoutes"));
const DR2Routes = lazy(() => import("../pages/gamesStats/dirtRally2/DR2Routes"));
const D5Routes = lazy(() => import("../pages/gamesStats/dirt5/D5Routes"));
const GRID21Routes = lazy(() => import("../pages/gamesStats/GRID21/GRID21Routes"));
const F121Routes = lazy(() => import("../pages/gamesStats/F12021/F12021Routes"));
const F122Routes = lazy(() => import("../pages/gamesStats/F12022/F12022Routes"));
const F123Routes = lazy(() => import("../pages/gamesStats/F12023/F12023Routes"));
const F124Routes = lazy(() => import("../pages/gamesStats/F12024/F12024Routes"));
const F125Routes = lazy(() => import("../pages/gamesStats/F12025/F12025Routes"));
const WRC2023Routes = lazy(() => import("../pages/gamesStats/WRC2023/WRC2023Routes"));
const UnderConstruction = lazy(() => import("../pages/gamesStats/components/UnderConstruction"));

export default function AuthorisedRoutes() {
    const isMobile = useIsMobile();
    const identityContext = useIdentityContext();
    const applicationContext = useApplicationContext();
    const showInstallAppBanner = useConfig().features?.find((f) => f === "mobile_app_install");

    const gameStatsRoutes = applicationContext.state.applicationStore.supportedGames.map(
        ({ route, gameId, isRacenetEnabled, title }) => {
            if (!isRacenetEnabled || route === undefined || route === "") return null;

            let children = (
                <UnderConstruction
                    gameTitle={title}
                    surveyUrl={applicationContext.state.applicationStore.gameStatsSurveyUrl}
                />
            );

            if (gameId === GamesEnum.DirtRally) {
                children = (
                    <>
                        <Helmet>
                            <title>{`${TabTitle.RaceNet} - ${title}`}</title>
                        </Helmet>
                        <DRRoutes titleBase={`${TabTitle.RaceNet} - ${title}`} />
                    </>
                );
            } else if (gameId === GamesEnum.DirtRally2) {
                children = (
                    <>
                        <Helmet>
                            <title>{`${TabTitle.RaceNet} - ${title}`}</title>
                        </Helmet>
                        <DR2Routes titleBase={`${TabTitle.RaceNet} - ${title}`} />
                    </>
                );
            } else if (gameId === GamesEnum.Dirt5) {
                children = (
                    <>
                        <Helmet>
                            <title>{`${TabTitle.RaceNet} - ${title}`}</title>
                        </Helmet>
                        <D5Routes titleBase={`${TabTitle.RaceNet} - ${title}`} />
                    </>
                );
            } else if (gameId === GamesEnum.Grid2021) {
                children = (
                    <>
                        <Helmet>
                            <title>{`${TabTitle.RaceNet} - ${title}`}</title>
                        </Helmet>
                        <GRID21Routes titleBase={`${TabTitle.RaceNet} - ${title}`} />
                    </>
                );
            } else if (gameId === GamesEnum.F12021) {
                children = (
                    <>
                        <Helmet>
                            <title>{`${TabTitle.RaceNet} - ${title}`}</title>
                        </Helmet>
                        <F121Routes titleBase={`${TabTitle.RaceNet} - ${title}`} />
                    </>
                );
            } else if (gameId === GamesEnum.F12022) {
                children = (
                    <>
                        <Helmet>
                            <title>{`${TabTitle.RaceNet} - ${title}`}</title>
                        </Helmet>
                        <F122Routes titleBase={`${TabTitle.RaceNet} - ${title}`} />
                    </>
                );
            } else if (gameId === GamesEnum.F12023) {
                children = (
                    <>
                        <Helmet>
                            <title>{`${TabTitle.RaceNet} - ${title}`}</title>
                        </Helmet>
                        <F123Routes titleBase={`${TabTitle.RaceNet} - ${title}`} />
                    </>
                );
            } else if (gameId === GamesEnum.WRC2023) {
                children = (
                    <>
                        <Helmet>
                            <title>{`${TabTitle.RaceNet} - ${title}`}</title>
                        </Helmet>
                        <WRC2023Routes titleBase={`${TabTitle.RaceNet} - ${title}`} />
                    </>
                );
            } else if (gameId === GamesEnum.F12024) {
                children = (
                    <>
                        <Helmet>
                            <title>{`${TabTitle.RaceNet} - ${title}`}</title>
                        </Helmet>
                        <F124Routes titleBase={`${TabTitle.RaceNet} - ${title}`} />
                    </>
                );
            } else if (gameId === GamesEnum.F12025) {
                children = (
                    <>
                        <Helmet>
                            <title>{`${TabTitle.RaceNet} - ${title}`}</title>
                        </Helmet>
                        <F125Routes titleBase={`${TabTitle.RaceNet} - ${title}`} />
                    </>
                );
            }

            return (
                <Route key={`route_${gameId}`} path={route}>
                    {children}
                </Route>
            );
        }
    );

    return (
        <>
            {showInstallAppBanner && <LaunchDialogsController />}
            <ScrollToTop />
            <NotificationContextProvider>
                <AuthorisedNavigationBar>
                    {!isRaceNetMobileApp && !isRaceNetTabletApp && !isMobile && <OnboardingDialog />}
                    <Suspense fallback={<PageCircularProgress />}>
                        <Switch>
                            {/* NAVIGATION BAR ROUTES */}
                            <Route path={routingUrls.profile}>
                                <Helmet>
                                    <title>{`${TabTitle.RaceNet} - ${TabTitle.Profile}`}</title>
                                </Helmet>
                                <ProfileRoutes />
                            </Route>
                            <Route exact path={routingUrls.games}>
                                <Helmet>
                                    <title>{`${TabTitle.RaceNet} - ${TabTitle.GameLib}`}</title>
                                </Helmet>
                                <Games />
                            </Route>
                            {/* MISC ROUTES */}
                            <Route exact path={routingUrls.friends}>
                                <Helmet>
                                    <title>{`${TabTitle.RaceNet} - ${TabTitle.Friends}`}</title>
                                </Helmet>
                                <Friends />
                            </Route>

                            {gameStatsRoutes}

                            <Route exact path={routingUrls.preferences}>
                                <Helmet>
                                    <title>{`${TabTitle.RaceNet} - ${TabTitle.Settings}`}</title>
                                </Helmet>
                                <Preferences />
                            </Route>
                            <Route path={routingUrls.newsFeed}>
                                <Helmet>
                                    <title>EA Racenet - News</title>
                                </Helmet>
                                <News />
                            </Route>
                            <Route path={`${routingUrls.releaseNotes}/:version?`}>
                                <Helmet>
                                    <title>{`${TabTitle.RaceNet} - ${TabTitle.ReleaseNotes}`}</title>
                                </Helmet>
                                <ReleaseNotes />
                            </Route>
                            <Route path={routingUrls.faq}>
                                <Helmet>
                                    <title>{`${TabTitle.RaceNet} - ${TabTitle.Faq}`}</title>
                                </Helmet>
                                <Faq />
                            </Route>
                            <Redirect exact from="/" to={routingUrls.games} />
                            <Route
                                path={routingUrls.errorPages[404]}
                                render={() => (
                                    <>
                                        <Helmet>
                                            <title>{`${TabTitle.RaceNet} - ${TabTitle.NotFound}`}</title>
                                        </Helmet>
                                        <LoggedInNotFoundPage />
                                    </>
                                )}
                            />
                            <Route
                                render={() => (
                                    <>
                                        <Helmet>
                                            <title>{`${TabTitle.RaceNet} - ${TabTitle.NotFound}`}</title>
                                        </Helmet>
                                        <LoggedInNotFoundPage />
                                    </>
                                )}
                            />
                        </Switch>
                    </Suspense>
                </AuthorisedNavigationBar>
            </NotificationContextProvider>

            {/*For analytics to retrieve the ssid*/}
            <input id={analyticsIds.identitySsid} type="hidden" value={identityContext.state.identity.ssid} />
        </>
    );
}
